<template>
    <div>
        <b-row>
            <b-col cols="6">
                <b-form-group :label="$t('certificates_id')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.code) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('certificates_name')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.name) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('start_date')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.start_date) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('end_date')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.end_date) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('document_type')">
                    <b-input-group>
                        <div class="label-as-input">{{ $i18n.locale == 'tr' ? form.document_type_name : form.document_type_name_en }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t('document_draft')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.document_template) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('instructors')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.instructor_names) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('has_teams_integration')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.has_teams_integration ? $t('there_is') : $t('absent') ) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('allow_epayment')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.allow_epayment ? $t('there_is') : $t('absent') ) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="4">
                <b-form-group :label="$t('allow_vinov')">
                    <b-input-group>
                        <div class="label-as-input">{{ isNotNullValue(form.allow_vinov ? $t('there_is') : $t('absent') ) }}</div>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col cols="12">
                <b-form-group :label="$t('content')">
                    <b-input-group>
                        <div v-html="form.content" class="label-as-input"></div>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    //Service
    import CertificateCenterCertificateService from '@/services/CertificateCenterCertificateService';

    export default {
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                form: {
                    name: null,
                    code: null,
                    start_date: null,
                    end_date: null,
                    document_type: null,
                    document_template: null,
                    content: null,
                    instructor_names: [],
                    has_teams_integration: null,
                    allow_epayment: null,
                    allow_vinov: null,
                }
            }
        },
        created() {
            this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                CertificateCenterCertificateService.get(id)
                                .then((response) => {
                                    this.form = response.data.data;
                                    this.formLoading = false;
                                    this.form.instructor_names =  response.data.data.instructor_names.length > 1 ? response.data.data.instructor_names.map(instructor => instructor.full_name).join(', ') : response.data.data.instructor_names[0].full_name
                                    this.form.content = this.decodeHTMLEntities(this.form.content)
                                })
                                .catch((error) => {
                                    this.showErrors(error)
                                });
            }
        }
    }
</script>
